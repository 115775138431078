import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/twitter-instant-win.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Twitterインスタントウィン"
		  desc="このサンプルではTwitterで２つアクションを行うと、クーポンコードが提供されるように設計されています。即効性の高いインスタントウィンで賞品提供を行い、Twitterのアクションで拡散とリテンション効果を高めます。クーポンコードを表示しすぐにECサイトに誘導します。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter/instant-win'}
		  type={'Article'}
		  shortName="Twitterインスタントウィンの事例・サンプル"
		  createdAt="2021-11-26"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="Twitterインスタントウィン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Twitterインスタントウィン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/twitter',
		  name: 'Twitterキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Twitterインスタントウィン"}
		description={<>このサンプルではTwitterで２つアクションを行うと、クーポンコードが提供されるように設計されています。即効性の高いインスタントウィンで賞品提供を行い、Twitterのアクションで拡散とリテンション効果を高めます。<br/>クーポンコードを表示しすぐにECサイトに誘導します。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS, Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD, Constants.CAMPAIGN_EFFECTS.SALE_PROMOTION,
		  , Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE, Constants.CAMPAIGN_EFFECTS.RETENTION, Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION]}
		plan={'ライトプラン以上'}
		promotionId="524069f39415c9c7a9"
		platformExample={
		  <PlatformExample
			title="その他のTwitterキャンペーン"
			types={['twitterFollowRetweet', 'twitterFollowLike', 'twitterFollowHashtag','twitterToLine','twitterInstantWin','twitterMultipleAccounts']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples